import { DESIGN, PHOTOGRAPHY, ROUTE_MAPPING } from "./constants";

const EIE4 = {
  title: "E&E vintage fashion III",
  category: PHOTOGRAPHY,
  subcategory: ROUTE_MAPPING[PHOTOGRAPHY].COMMERCIAL,
  slug: "eie4",
  text: "Session done for promoting purposes of the 'Femme Fatale' retro collection for E&E vintage fashion store based in Split, Croatia.",
  year: "2022.",
  model: "Petra Ana Čubelić",
  client: "E&E vintage fashion",
  collaborator: "Ela Braović, Ena Staničić",
  thumbnail: "/img/thumbnail_eieIV.jpg",
  images: [
    {
      src: "/img/eieIV1.jpg",
      text: "",
    },
    {
      src: "/img/eieIV2.jpg",
      text: "",
    },
    {
      src: "/img/eieIV3.jpg",
      text: "",
    },
    {
      src: "/img/eieIV4.jpg",
      text: "",
    },
    {
      src: "/img/eieIV5.jpg",
      text: "",
    },
    {
      src: "/img/eieIV6.jpg",
      text: "",
    },
    
  ],
};

const EIE3 = {
  title: "E&E vintage fashion II",
  category: PHOTOGRAPHY,
  subcategory: ROUTE_MAPPING[PHOTOGRAPHY].COMMERCIAL,
  slug: "eie3",
  text: "Session done for promoting purposes of the 'Wild Summer' retro collection for E&E vintage fashion store based in Split, Croatia.",
  year: "2022.",
  model: "Slavena Verić",
  client: "E&E vintage fashion",
  collaborator: "Ela Braović, Ena Staničić",
  thumbnail: "/img/thumbnail_eieIII.jpg",
  images: [
    {
      src: "/img/eieIII1.jpg",
      text: "",
    },
    {
      src: "/img/eieIII2.jpg",
      text: "",
    },
    {
      src: "/img/eieIII3.jpg",
      text: "",
    },
    {
      src: "/img/eieIII4.jpg",
      text: "",
    },
    {
      src: "/img/eieIII5.jpg",
      text: "",
    },
    {
      src: "/img/eieIII6.jpg",
      text: "",
    },
    {
      src: "/img/eieIII7.jpg",
      text: "",
    },
    {
      src: "/img/eieIII8.jpg",
      text: "",
    },
    {
      src: "/img/eieIII9.jpg",
      text: "",
    },
    
  ],
};



const SYNC = {
  title: "Sync",
  category: PHOTOGRAPHY,
  subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
  slug: "sync",
  text: "",
  year: "2021.",
  model: "Tina Lucić, Ana Miletić",
  client: "",
  collaborator: "",
  thumbnail: "/img/thumbnail_sync.jpg",
  images: [
    {
      src: "/img/sync1.jpg",
      text: "",
    },
    {
      src: "/img/sync2.jpg",
      text: "",
    },
    {
      src: "/img/sync3.jpg",
      text: "",
    },
    {
      src: "/img/sync4.jpg",
      text: "",
    },
    {
      src: "/img/sync5.jpg",
      text: "",
    },
    {
      src: "/img/sync6.jpg",
      text: "",
    },
    {
      src: "/img/sync7.jpg",
      text: "",
    },
    {
      src: "/img/sync8.jpg",
      text: "",
    },
    {
      src: "/img/sync9.jpg",
      text: "",
    },
    {
      src: "/img/sync10.jpg",
      text: "",
    },
    
  ],
};


const SLAVENA = {
  title: "Slavena",
  category: PHOTOGRAPHY,
  subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
  slug: "slavena",
  text: "An afternoon with a barbarian high priestess (colorized).",
  year: "2020.",
  model: "Slavena Verić",
  client: "",
  collaborator: "",
  thumbnail: "/img/thumbnail_slavena.jpg",
  images: [
    {
      src: "/img/sla1.jpg",
      text: "",
    },
    {
      src: "/img/sla2.jpg",
      text: "",
    },
    {
      src: "/img/sla3.jpg",
      text: "",
    },
    {
      src: "/img/sla4.jpg",
      text: "",
    },
    {
      src: "/img/sla5.jpg",
      text: "",
    },
    {
      src: "/img/sla6.jpg",
      text: "",
    },
    {
      src: "/img/sla7.jpg",
      text: "",
    },
    {
      src: "/img/sla8.jpg",
      text: "",
    },
    {
      src: "/img/sla9.jpg",
      text: "",
    },
  ],
};

const SPLIT = {
  title: "From Split, with love",
  category: PHOTOGRAPHY,
  subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
  slug: "split",
  text: "From Split, with Love je projekt koji proučava lokalni mentalitet i zatim isti pretače u prostor kroz medij fotografije i tipografije. Pomoću citata osoba koje su povezane sa Splitom, pokušava se obuhvatiti širi kontekst Splitskih istomišljenika, i pritom dati humorističan pogled na iste. Kako bi fotografija djelovala realno, element spontanosti pokušao se dobiti sjedinjujući tipografiju s okolinom, na način da tipografija postaje stvarni, materijalni suučesnik na fotografiji, tako da se ili stavlja u okolinu spomenutih istomišljenika ili je napravljena od elemenata koji su povezani uz izrečenu izjavu. Dok izvori izjava variraju od nestvarnih osoba poput doktora Luiđija, do stvarnih osoba poput Gorana Ivaniševića, pa sve do lokalnih ‘redikula’ koji na neko vrijeme bivaju Youtube senzacija, svaka izjava bavi se psihološkom, a time i sociološkom karakteristikom ovog područja i na isto putem vizualnog jezika daje komentar. Zatim je ideja, ne bi li se naglasila ironija, pretočena u oblik razglednica koje funkcioniraju kao turistička promocija grada Splita.",
  year: "2021.",
  model: "",
  client: "UMAS, DVK",
  collaborator: "Nikša Vukša",
  thumbnail: "/img/thumbnail_split3.jpg",
  images: [
    {
      src: "/img/split1.jpg",
      text: "‘A u libri si ka tuka’ je izjava dotura Luiđija, protagonista serijala “Naše malo misto”, uperena u njegovu suprugu Bepinu. Vizualom od klasičnog dalmatinskog ručka ‘manistre na pome’ pokušao se dočarati običaj u kojem se splitske matrijarhe, (barem od starijih generacija) često vežu uz domaćinstvo što im ne ostavlja mnogo vremena za nauku ili riječima dotura “lov, balote, pase i medićinu”. Ujedno služi kao i komentar na prirodu odnosa supružnika u usporedbi s mlađim generacijama.",
    },
    {
      src: "/img/split2.jpg",
      text: "‘U zadnje vrime jebiga’ je izjava tenisača Gorana Ivaniševića u kojoj na novinarskoj presici daje svoj komentar o prethodnom meču. Situacija aludira na prostodušan, jezični šarm prosječnog Splićanina, a ujedno i nesposobnost odvajanja službene od neslužbene diskusije, koja je također često sasvim nonšalantno prožeta profanizmima. ",
    },
    {
      src: "/img/split3.jpg",
      text: "‘Lito ide mala’ izjava je izvučena iz istoimene pjesme Kuzme i Shake Zulu. Dok je tokom godina rečenica dobila ambivalentno značenje, u ovom kontekstu aludira na raspoloženje i misao vodilju lokalnih (i stranih) aktualnih glazbenih hitova, koji uzimaju maha ljeti, a uglavnom impliciraju na promiskuitetnost i prolaznost života.",
    },
    {
      src: "/img/kartolina.jpg",
      text: "",
    },
    {
      src: "/img/split4.jpg",
      text: "‘Bože dragi hodan’ izjava je također anonimnog stanovnika Geta u videu kojeg je objavila Facebook grupa “Policija zaustavlja” pod imenom “St stanje uma”, u kojem je dotični (izvan konteksta) oduševljen činjenicom svoje sposobnosti hodanja. Vizual aludira na dobroćudnost lokalnih pijanaca, pa je time predočen u krhotinama stakla razbivenih boca domaćih piva, pod prikladnim ambijentom svijetala Matejuške.",
    },
    {
      src: "/img/split5.jpg",
      text: "Ća će mi Copacabana’ stih je iz istoimene pjesme Olivera Dragojevića koja govori o posebnosti grada Splita, te njegovoj nadmoći nad nekim ostalim priobalnim gradovima. Koristeći se mediteranskim motivima galeba, odnosno galebljevog izmeta, aludira se na klišejiziran stav o domoljublju i nepotkovanu glorifikacĳu nacionalnog identiteta.",
    },
    {
      src: "/img/split6.jpg",
      text: "‘Mama baci mi 10 kuna’ je izjava anonimnog žitelja Splita koji na kratkom popularnom Youtube videu ispod majčinog prozora nervozno pita istu za 10 kuna kako bi popio pivo. Vizual je komentar na učestalu situaciju u kojoj splitske obitelji često odgajaju djecu kako bi postala hendikepirana od samoostvarenja, što je često postala norma zbog arhitekture rasporeda kućanstava, te generalnog problema zapošljavanja mladih u Hrvatskoj. Predočujući tekst kroz tetovažu koja se vodi estetikom mladeži pod utjecajem street i gangsta rap kulture, imitira se stil kojim bi inače pisalo “Ivana” ili “Hajduk”.",
    },
    {
      src: "/img/split7.jpg",
      text: "‘Vozin 200’ je izjava poduzetnika i političara Željka Keruma u HRT-ovoj emisiji “Fokus”, u kojoj bezbrižno odgovara na pitanje o brzoj vožnji. Vizual se referira na poznatu situaciju u predizbornoj kampanji gdje je dotični na Čiovu čelo upaljeno od sunca hladio oblogom od pršuta, što je 2013. pokrenulo internetski trend. Spajajući ova dva ikonična događaja pokušao se dočarati hedonizam i komoditet u kojima uživaju osobe na poziciji.",
    },
  ],
};



const ANA = {
  title: "Ana",
  category: PHOTOGRAPHY,
  subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
  slug: "ana",
  text: "Comissioned session done for Ana.",
  year: "2021.",
  model: "Ana Rusić",
  client: "",
  collaborator: "Petra Ana Čubelić",
  thumbnail: "/img/thumbnail_ana.jpg",
  images: [
    {
      src: "/img/ana1.jpg",
      text: "",
    },
    {
      src: "/img/ana2.jpg",
      text: "",
    },
    {
      src: "/img/ana3.jpg",
      text: "",
    },
    {
      src: "/img/ana4.jpg",
      text: "",
    },
    {
      src: "/img/ana5.jpg",
      text: "",
    },
    {
      src: "/img/ana6.jpg",
      text: "",
    },
    {
      src: "/img/ana7.jpg",
      text: "",
    },
    {
      src: "/img/ana8.jpg",
      text: "",
    },
    {
      src: "/img/ana9.jpg",
      text: "",
    },
    {
      src: "/img/ana10.jpg",
      text: "",
    },
    {
      src: "/img/ana11.jpg",
      text: "",
    },
  ],
};


const PROCESSING = {
  title: "Processing",
  category: PHOTOGRAPHY,
  subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
  slug: "processing",
  text: "A project that came to life while trying to learn the programming language of Processing. Animations were done by hand and then projected onto the body of a model, trying to accentuate the curves of the body with lines in the animation aswell as incorporate them in the pattern of the skin.",
  year: "2020.",
  model: "Tina Lucić",
  client: "",
  collaborator: "Bruno Dubravec",
  thumbnail: "/img/thumbnail_processing2.jpg",
  images: [
    {
      src: "/img/processing1.jpg",
      text: "",
    },
    {
      src: "/img/processing2.jpg",
      text: "",
    },
    {
      src: "/img/processing3.jpg",
      text: "",
    },
    {
      src: "/img/processing4.jpg",
      text: "",
    },
    {
      src: "/img/processing5.jpg",
      text: "",
    },
    {
      src: "/img/processing6.jpg",
      text: "",
    },
    {
      src: "/img/processing7.jpg",
      text: "",
    },
    {
      src: "/img/processing8.jpg",
      text: "",
    },
    {
      src: "/img/processing9.jpg",
      text: "",
    },
    {
      src: "/img/processing10.jpg",
      text: "",
    },
    {
      src: "/img/processing11.jpg",
      text: "",
    },
    {
      src: "/img/processing12.jpg",
      text: "",
    },
    {
      src: "/img/processing14.jpg",
      text: "",
    },
    {
      src: "/img/processing15.jpg",
      text: "",
    }
  ],
};


const MEDITERRA = {
  title: "Mediterra",
  category: PHOTOGRAPHY,
  subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
  slug: "mediterra",
  text: "",
  year: "2019./2020./2021.",
  model: "Various",
  client: "",
  collaborator: "",
  thumbnail: "/img/thumbnail_mediterra.jpg",
  images: [
    {
      src: "/img/m0.jpg",
      text: "",
    },
    {
      src: "/img/m1.jpg",
      text: "",
    },
    {
      src: "/img/m2.jpg",
      text: "",
    },
    {
      src: "/img/m3.jpg",
      text: "",
    },
    {
      src: "/img/m4.jpg",
      text: "",
    },
    {
      src: "/img/m5.jpg",
      text: "",
    },

    
    {
      src: "/img/m7.jpg",
      text: "",
    },
    {
      src: "/img/m8.jpg",
      text: "",
    },
    {
      src: "/img/m9.jpg",
      text: "",
    },
    {
      src: "/img/m10.jpg",
      text: "",
    },
    {
      src: "/img/m11.jpg",
      text: "",
    },
    {
      src: "/img/m12.jpg",
      text: "",
    },
    {
      src: "/img/m13.jpg",
      text: "",
    },
    {
      src: "/img/m14.jpg",
      text: "",
    },
    {
      src: "/img/m15.jpg",
      text: "",
    },
    {
      src: "/img/m16.jpg",
      text: "",
    },
    {
      src: "/img/m17.jpg",
      text: "",
    },
    {
      src: "/img/m18.jpg",
      text: "",
    },
    {
      src: "/img/m19.jpg",
      text: "",
    },
    {
      src: "/img/m20.jpg",
      text: "",
    },
    {
      src: "/img/m21.jpg",
      text: "",
    },
    {
      src: "/img/m22.jpg",
      text: "",
    },
    {
      src: "/img/m23.jpg",
      text: "",
    },
    {
      src: "/img/m24.jpg",
      text: "",
    },
    {
      src: "/img/m25.jpg",
      text: "",
    },
    {
      src: "/img/m26.jpg",
      text: "",
    },
    {
      src: "/img/m27.jpg",
      text: "",
    },
    {
      src: "/img/m28.jpg",
      text: "",
    },
    {
      src: "/img/m29.jpg",
      text: "",
    },
    {
      src: "/img/m30.jpg",
      text: "",
    },
    {
      src: "/img/m31.jpg",
      text: "",
    },
    {
      src: "/img/m32.jpg",
      text: "",
    },
    {
      src: "/img/m33.jpg",
      text: "",
    },
    {
      src: "/img/m34.jpg",
      text: "",
    },
    {
      src: "/img/m35.jpg",
      text: "",
    },
    {
      src: "/img/m36.jpg",
      text: "",
    },
    {
      src: "/img/m37.jpg",
      text: "",
    },
    {
      src: "/img/m38.jpg",
      text: "",
    },
    {
      src: "/img/m39.jpg",
      text: "",
    },
    {
      src: "/img/m40.jpg",
      text: "",
    },
    {
      src: "/img/m41.jpg",
      text: "",
    },
    {
      src: "/img/m42.jpg",
      text: "",
    },
    {
      src: "/img/m43.jpg",
      text: "",
    },
    {
      src: "/img/m44.jpg",
      text: "",
    },
    {
      src: "/img/m45.jpg",
      text: "",
    },
    {
      src: "/img/m46.jpg",
      text: "",
    },
    {
      src: "/img/m47.jpg",
      text: "",
    },
    {
      src: "/img/m48.jpg",
      text: "",
    },
    {
      src: "/img/m49.jpg",
      text: "",
    },
    {
      src: "/img/m50.jpg",
      text: "",
    },
    {
      src: "/img/m51.jpg",
      text: "",
    },
    {
      src: "/img/m52.jpg",
      text: "",
    },
    {
      src: "/img/m53.jpg",
      text: "",
    },
    {
      src: "/img/m54.jpg",
      text: "",
    },
    {
      src: "/img/m55.jpg",
      text: "",
    },
    {
      src: "/img/m56.jpg",
      text: "",
    }

  ],
};

const EIE = {
      title: "E&E vintage fashion",
      category: PHOTOGRAPHY,
      subcategory: ROUTE_MAPPING[PHOTOGRAPHY].COMMERCIAL,
      slug: "eie",
      text: "Session done for promoting purposes of the 'Softy' retro collection for E&E vintage fashion store based in Split, Croatia.",
      year: "2021.",
      model: "Mia Ibrišimović",
      client: "E&E vintage fashion",
      collaborator: "Ela Braović, Ena Staničić",
      thumbnail: "/img/thumbnail_eie2.jpg",
      images: [
        {
          src: "/img/eie1.jpg",
          text: "",
        },
        {
          src: "/img/eie2.jpg",
          text: "",
        },
        {
          src: "/img/eie3.jpg",
          text: "",
        },
        {
          src: "/img/eie4.jpg",
          text: "",
        },
        {
          src: "/img/eie5.jpg",
          text: "",
        },
        {
          src: "/img/eie6.jpg",
          text: "",
        },
        {
          src: "/img/eie7.jpg",
          text: "",
        },
        {
          src: "/img/eie8.jpg",
          text: "",
        },
        {
          src: "/img/eie9.jpg",
          text: "",
        },
        {
          src: "/img/eie10.jpg",
          text: "",
        },
        {
          src: "/img/eie11.jpg",
          text: "",
        }
      ],
    };


const ANGELINA = {
      title: "Angelina jewelry",
      category: PHOTOGRAPHY,
      subcategory: ROUTE_MAPPING[PHOTOGRAPHY].COMMERCIAL,
      slug: "angelina",
      text: "Campaign ad made for promoting purposes of the summer collection for Angelina handmade jewelry in Split, Croatia.",
      year: "2021.",
      model: "Ivana Rogošić",
      client: "Angelina jewelry",
      thumbnail: "/img/thumbnail_ursula4.jpg",
      images: [
        {
          src: "/img/ange1.jpg",
          text: "",
        },
        {
          src: "/img/ange2.jpg",
          text: "",
        },
        {
          src: "/img/ange3.jpg",
          text: "",
        },
        {
          src: "/img/ange4.jpg",
          text: "",
        },
        {
          src: "/img/ange5.jpg",
          text: "",
        },
        {
          src: "/img/ange6.jpg",
          text: "",
        },
        {
          src: "/img/ange7.jpg",
          text: "",
        },
        {
          src: "/img/ange8.jpg",
          text: "",
        },
        {
          src: "/img/ange9.jpg",
          text: "",
        },
        {
          src: "/img/ange10.jpg",
          text: "",
        },
        {
          src: "/img/ange11.jpg",
          text: "",
        },
        {
          src: "/img/ange12.jpg",
          text: "",
        },
        {
          src: "/img/ange13.jpg",
          text: "",
        },
        {
          src: "/img/ange14.jpg",
          text: "",
        },
        {
          src: "/img/ange15.jpg",
          text: "",
        },
        {
          src: "/img/ange16.jpg",
          text: "",
        }
      ],
    };


const KARANTINA = {
      title: "Karantina",
      category: PHOTOGRAPHY,
      subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
      slug: "karantina",
      text: "Due to the ongoing Covid-19 pandemic incident, I was evacuated by the embassy from my lovely flat in Seville, Spain - back to my hometown of Split, Croatia. Upon arriving at the borders I was ordered by the police to a strict 14-day self-isolation at home. I tried to visualize the frustration of being bound to my home by uniting the model with furniture and transforming her into an 'object' in the living area, which is exactly what quarantine felt like - becoming one with the space. Oddly enough, the model's name is Tina, hence the pun and the title of the session, which sounds like a Croatian word for Quarantine.",
      year: "2020.",
      client: "",
      collaborator: "Bruno Dubravec",
      thumbnail: "/img/thumbnail_karantina.jpg",
      images: [
        {
          src: "/img/karantina1.jpg",
          text: "",
        },
        {
          src: "/img/karantina2.jpg",
          text: "",
        },
        {
          src: "/img/karantina3.jpg",
          text: "",
        },
        {
          src: "/img/karantina4.jpg",
          text: "",
        },
        {
          src: "/img/karantina5.jpg",
          text: "",
        },
        {
          src: "/img/karantina6.jpg",
          text: "",
        },
        {
          src: "/img/karantina7.jpg",
          text: "",
        },
        {
          src: "/img/karantina8.jpg",
          text: "",
        },
        {
          src: "/img/karantina9.jpg",
          text: "",
        },
        {
          src: "/img/karantina10.jpg",
          text: "",
        },
        {
          src: "/img/karantina12.jpg",
          text: "",
        },
        {
          src: "/img/karantina13.jpg",
          text: "",
        },
        {
          src: "/img/karantina14.jpg",
          text: "",
        },
        {
          src: "/img/karantina15.jpg",
          text: "",
        },
        {
          src: "/img/karantina16.jpg",
          text: "",
        },
        {
          src: "/img/karantina17.jpg",
          text: "",
        },
        {
          src: "/img/karantina18.jpg",
          text: "",
        },
        {
          src: "/img/karantina19.jpg",
          text: "",
        },
        {
          src: "/img/karantina20.jpg",
          text: "",
        },
        {
          src: "/img/karantina21.jpg",
          text: "",
        },
        {
          src: "/img/karantina22.jpg",
          text: "",
        },
        {
          src: "/img/karantina23.jpg",
          text: "",
        },
        {
          src: "/img/karantina25.jpg",
          text: "",
        },

      ],
    };



    const MIRAGE = {
      title: "Mirage",
      category: PHOTOGRAPHY,
      subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
      slug: "mirage",
      text: "A play of symmetry done with two close friends in a hotel room in Zagreb.",
      year: "2019.",
      client: "",
      collaborator: "",
      thumbnail: "/img/thumbnail_palace2.jpg",
      images: [
        {
          src: "/img/palace1.jpg",
          text: "",
        },
        {
          src: "/img/palace2.jpg",
          text: "",
        },
        {
          src: "/img/palace3.jpg",
          text: "",
        },
        {
          src: "/img/palace4.jpg",
          text: "",
        },
        {
          src: "/img/palace5.jpg",
          text: "",
        },
        {
          src: "/img/palace6.jpg",
          text: "",
        },
        {
          src: "/img/palace7.jpg",
          text: "",
        },
        {
          src: "/img/palace8.jpg",
          text: "",
        },
        {
          src: "/img/palace9.jpg",
          text: "",
        },
        {
          src: "/img/palace10.jpg",
          text: "",
        },
      ],
    };


    const CLIO = {
      title: "Clio",
      category: PHOTOGRAPHY,
      subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
      slug: "clio",
      text: "Unofficial promo for the Renault Clio III car.",
      year: "2018.",
      client: "",
      collaborator: "",
      thumbnail: "/img/thumbnail_clio.jpg",
      images: [
        {
          src: "/img/clio3.jpg",
          text: "",
        },
        {
          src: "/img/clio4.jpg",
          text: "",
        },
        {
          src: "/img/clio5.jpg",
          text: "",
        },
        {
          src: "/img/clio6.jpg",
          text: "",
        },
        {
          src: "/img/clio7.jpg",
          text: "",
        },
        {
          src: "/img/clio10.jpg",
          text: "",
        },
        {
          src: "/img/clio11.jpg",
          text: "",
        },
        {
          src: "/img/clio12.jpg",
          text: "",
        },
        {
          src: "/img/clio13.jpg",
          text: "",
        },
        {
          src: "/img/clio14.jpg",
          text: "",
        },
        {
          src: "/img/clio18.jpg",
          text: "",
        },
      ],
    };
  

    const MAMMALIA = {
      title: "Mammalia",
      category: PHOTOGRAPHY,
      subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
      slug: "mammalia",
      text: "The series is a part of a bigger project dealing with the female nude in unconventional spaces, with the aim of destigmatizing the body, specifically – the female breasts. The idea is to visually accentuate the beauty of the female form in the freedom of movement through surroundings with no social barriers related to behavior or attire. The collective usually acts unplanned, on the principle of spontaneous undressing and intervening in the ambience and hence this shoot also happened while on an impromptu drive through the landscapes of Montenegro. By placing the models next to animals, or to be precise – cows, that symbolically have their breasts explicitly exposed compared to other mammals, we tried to create a juxtaposition with ‘free mammals’ in their natural habitat, and thus emphasize the absurdity of the concept of the human nipple.",
      year: "2018.",
      client: "",
      collaborator: "",
      thumbnail: "/img/thumbnail_krave.jpg",
      images: [
        {
          src: "/img/k0.jpg",
          text: "",
        },
        {
          src: "/img/k1.jpg",
          text: "",
        },
        {
          src: "/img/k2.jpg",
          text: "",
        },
        {
          src: "/img/k3.jpg",
          text: "",
        },
        {
          src: "/img/k4.jpg",
          text: "",
        },
        {
          src: "/img/k5.jpg",
          text: "",
        },
        {
          src: "/img/k6.jpg",
          text: "",
        },
        {
          src: "/img/k7.jpg",
          text: "",
        },
        {
          src: "/img/k8.jpg",
          text: "",
        },
        {
          src: "/img/k9.jpg",
          text: "",
        },
        {
          src: "/img/k10.jpg",
          text: "",
        },
        {
          src: "/img/k11.jpg",
          text: "",
        },
        {
          src: "/img/k12.jpg",
          text: "",
        },
        {
          src: "/img/k13.jpg",
          text: "",
        },
        {
          src: "/img/k14.jpg",
          text: "",
        },
        {
          src: "/img/k15.jpg",
          text: "",
        },
        {
          src: "/img/k16.jpg",
          text: "",
        },
        {
          src: "/img/k17.jpg",
          text: "",
        },
        {
          src: "/img/k18.jpg",
          text: "",
        },
        {
          src: "/img/k19.jpg",
          text: "",
        },
        {
          src: "/img/k20.jpg",
          text: "",
        },
        {
          src: "/img/k21.jpg",
          text: "",
        },
        {
          src: "/img/k22.jpg",
          text: "",
        },
      ],
    };


    const HEN = {
      title: "The Hen",
      category: PHOTOGRAPHY,
      subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
      slug: "hen",
      text: "A portrait session with a close friend done through a chicken/bird thematic, inspired by an endearing slur 'Koke', a term used between women in Split to address each other. Koke can also be used to describe a local glamourous girl which is how I envision my friend.",
      year: "2018.",
      client: "",
      collaborator: "",
      thumbnail: "/img/thumbnail_petraana.jpg",
      images: [
        
        {
          src: "/img/hen2.jpg",
          text: "",
        },
        {
          src: "/img/hen3.jpg",
          text: "",
        },
        {
          src: "/img/hen4.jpg",
          text: "",
        },
        {
          src: "/img/hen6.jpg",
          text: "",
        },
        {
          src: "/img/hen7.jpg",
          text: "",
        },
        {
          src: "/img/hen8.jpg",
          text: "",
        },
        {
          src: "/img/hen9.jpg",
          text: "",
        },
        {
          src: "/img/hen10.jpg",
          text: "",
        },
        {
          src: "/img/hen11.jpg",
          text: "",
        },
        {
          src: "/img/hen12.jpg",
          text: "",
        },
        {
          src: "/img/hen13.jpg",
          text: "",
        }
      ],
    };


    const FRENS = {
      title: "Frens",
      category: PHOTOGRAPHY,
      subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
      slug: "frens",
      text: "Portrait sessions done with my closest friends in symbolic surroundings, with elements that represent my personal memories and experiences of them, or just visions of our relationship.",
      year: "2018.",
      model: "Sara Čović, Tina Lucić, Slavena Verić, Tajana Čeko,  Ana Iva Aničić ",
      client: "",
      collaborator: "",
      thumbnail: "/img/thumbnail_frens2.jpg",
      images: [
        {
          src: "/img/sara1.jpg",
          text: "",
        },
        {
          src: "/img/sara2.jpg",
          text: "",
        },
        {
          src: "/img/sara3.jpg",
          text: "",
        },
        
        {
          src: "/img/slavena1.jpg",
          text: "",
        },
        {
          src: "/img/slavena3.jpg",
          text: "",
        },
        {
          src: "/img/tajana1.jpg",
          text: "",
        },
        {
          src: "/img/tajana3.jpg",
          text: "",
        },
        {
          src: "/img/anaiva1.jpg",
          text: "",
        },
        {
          src: "/img/anaiva2.jpg",
          text: "",
        },  
      ],
    };

    const SMOLL = {
      title: "Smöll",
      category: PHOTOGRAPHY,
      subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
      slug: "smoll",
      text: "An homage to insignificance by comparing a man to the vast landscapes of Iceland.",
      year: "2017.",
      model: "Alexandre Dalyac, Ana Miletić ",
      client: "",
      collaborator: "",
      thumbnail: "/img/thumbnail_iceland2.jpg",
      images: [
        {
          src: "/img/smol1.jpg",
          text: "",
        },
        {
          src: "/img/smol2.jpg",
          text: "",
        },
        {
          src: "/img/smol3.jpg",
          text: "",
        },
        {
          src: "/img/smol4.jpg",
          text: "",
        },
        {
          src: "/img/smol5.jpg",
          text: "",
        },
        {
          src: "/img/smol6.jpg",
          text: "",
        },
        {
          src: "/img/smol8.jpg",
          text: "",
        },
        {
          src: "/img/smol9.jpg",
          text: "",
        },
        {
          src: "/img/smol10.jpg",
          text: "",
        },
        {
          src: "/img/smol11.jpg",
          text: "",
        },
        {
          src: "/img/smol12.jpg",
          text: "",
        },
       
      ],
    };

    const DEA = {
      title: "Dea",
      category: PHOTOGRAPHY,
      subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
      slug: "dea",
      text: "An afternoon with an actress.",
      year: "2016.",
      model: "Dea Maria Računica ",
      client: "",
      collaborator: "",
      thumbnail: "/img/thumbnail_dea2.jpg",
      images: [
        {
          src: "/img/dea1.jpg",
          text: "",
        },
        {
          src: "/img/dea2.jpg",
          text: "",
        },
        {
          src: "/img/dea3.jpg",
          text: "",
        },
        {
          src: "/img/dea4.jpg",
          text: "",
        },
        {
          src: "/img/dea5.jpg",
          text: "",
        },
        {
          src: "/img/dea6.jpg",
          text: "",
        },
        {
          src: "/img/dea7.jpg",
          text: "",
        },
        {
          src: "/img/dea8.jpg",
          text: "",
        },
        {
          src: "/img/dea9.jpg",
          text: "",
        },
        {
          src: "/img/dea10.jpg",
          text: "",
        },
        {
          src: "/img/dea11.jpg",
          text: "",
        },
        {
          src: "/img/dea12.jpg",
          text: "",
        },
        {
          src: "/img/dea13.jpg",
          text: "",
        },
        {
          src: "/img/dea14.jpg",
          text: "",
        },
        {
          src: "/img/dea15.jpg",
          text: "",
        },

      ],
    };


    const KITCHEN = {
      title: "The Kitchen",
      category: PHOTOGRAPHY,
      subcategory: ROUTE_MAPPING[PHOTOGRAPHY].PERSONAL,
      slug: "kitchen",
      text: "Originally meant for implementation on a kitchen wall, The Kitchen is a family portrait that includes different ingredients which, when mixed together, make the favourite Miletic family meal - pancakes. Each ingredient (milk, egg, flour, sugar) symbolically corresponds to a family member's character trait.",
      year: "2015.",
      model: "Alen, Đurđica and Mateo Miletić",
      client: "",
      collaborator: "",
      thumbnail: "/img/thumbnail_kuzina.jpg",
      images: [
        {
          src: "/img/kitchen1.jpg",
          text: "",
        },
        
        {
          src: "/img/kitchen3.jpg",
          text: "",
        },
        {
          src: "/img/kitchen4.jpg",
          text: "",
        },
        {
          src: "/img/kitchen5.jpg",
          text: "",
        },
        {
          src: "/img/kitchen6.jpg",
          text: "",
        },
        {
          src: "/img/kitchen7.jpg",
          text: "",
        },
        

      ],
    };


    const ONCE = {
      title: "Once Upon a Time in the West",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].MISCELLANEOUS,
      slug: "once",
      text: "Split Nomad Gallery, in cooperation with Mediterranean Film Festival Split has announced the first edition of NoMAD MOVIE ART - Film classics through the eyes of local authors, of which I was picked as one, to portray a randomly assigned movie - 'Once upon a Time in the West'. The signed limited edition posters have been exhibited and sold as part of the festival merch at the Open Air Cinema Bačvice. The motive that has been chosen to portray was the one most signifacnt for me personally - a scene where the main character Harmonica throws a dollar in his whiskey shot, which was considered as a duel invitation with the anti-hero, in context of the movie. The photograph has also been staged and taken by me. The rest of the poster was made to resemble a classical 'Wanted' poster common for western movies.",
      year: "2022.",
      model: "",
      client: "Festival Mediteranskog Filma Split, Split Nomad Gallery ",
      collaborator: "",
      thumbnail: "/img/thumbnail_once2.jpg",
      images: [
         {
          src: "/img/onceuponatime.jpg",
          text: "",
        }
      ],
    };

    const ALPHA = {
      title: "Alpha Solutions",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].VISUAL_IDENTITY,
      slug: "alpha",
      text: "Alpha Solutions is an IT services company. The client wanted to incorporate Da Vinci's Vitruvian man in the identity, hence the Man was made out of components of a PCB (circuit board) giving the brand a 'tech' feel.",
      year: "2022.",
      model: "",
      client: "Alpha Solutions j.d.o.o.",
      collaborator: "",
      thumbnail: "/img/thumbnail_alpha.jpg",
      images: [
     {
          src: "/img/alphaexplanation.jpg",
          text: "",
        },
        {
          src: "/img/alphalogo.jpg",
          text: "",
        },
        {
          src: "/img/alphapalette.jpg",
          text: "",
        },
        {
          src: "/img/alphapattern.jpg",
          text: "",
        },
        {
          src: "/img/alphashirt.jpg",
          text: "",
        },
        {
          src: "/img/alphashirt2.jpg",
          text: "",
        },
        {
          src: "/img/alphatypo.jpg",
          text: "",
        },
        {
          src: "/img/alphasubmark.jpg",
          text: "",
        },
         {
          src: "/img/alphavizitke.jpg",
          text: "",
        }
      ],
    };

    const NFT = {
      title: "Event Horizon NFT",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].ILLUSTRATION,
      slug: "nft",
      text: "Event Horizon is a NFT project on the Cardano blockchain that features a compelling combination of narrative elements, puzzle games and codebreaking. The logo is derived out of the letter 'O' that appears twice in the word 'Horizon'. It is imagined as if it is teleporting through a black hole, which is an important part of the story lore. I have also illustrated 80 variants of scientists in forms of collectible cards, each with their inventions or significant motifs incorporated in the design. ",
      year: "2021.",
      model: "",
      client: <a href="https://twitter.com/eventhorizonft"> Event Horizon NFT</a>,
      collaborator: "Neven Katić, Bruno Rudolfi, Ivan Berecka",
      thumbnail: "/img/thumbnail_nft.jpg",
      images: [
        {
          src: "/img/eh1.png",
          text: "",
        },
        {
          src: "/img/eh2.png",
          text: "",
        },
        {
          src: "/img/eh3.png",
          text: "",
        },
        {
          src: "/img/eh4.png",
          text: "",
        },
        {
          src: "/img/eh5.png",
          text: "",
        },
        {
          src: "/img/eh6.png",
          text: "",
        },
        {
          src: "/img/eh7.png",
          text: "",
        },
        {
          src: "/img/eh8.png",
          text: "",
        },
         {
          src: "/img/eh9.png",
          text: "",
        },
        {
          src: "/img/eh10.png",
          text: "",
        }
      ],
    };

    const SUNSEARAY = {
      title: "Villa Sunsearay",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].VISUAL_IDENTITY,
      slug: "sunsearay",
      text: "Villa Sunsearay is a seaside villa in Dalmatia, still in the beginning of it's marketing process. The logo was created by imitating visuals for products often used on summer vacations - sunscreen, alcohol and cigarettes, all related to leisure, unwinding, and relaxation. The colors used were inspired by pine trees and sand, both surrounding the villa.",
      year: "2021.",
      model: "",
      client: "Villa Sunsearay",
      collaborator: "",
      thumbnail: "/img/thumbnail_sunsearay.jpg",
      images: [
        {
          src: "/img/sunsearay0.jpg",
          text: "",
        },
        {
          src: "/img/sunsearay1.jpg",
          text: "",
        },
      ],
    };

    const DRAGUN = {
      title: "Dragun Arhitekti / Ofis",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].MISCELLANEOUS,
      slug: "dragun",
      text: "The project was a 2 month collaboration with an architecture studio in which we made a complete rebranding of the logo and their visual identity in purpose of moving into a new office. The office was then split into 2 sectors - the actual office and a coworking coffee bar called - Ofis. After refurbishing their existing logo to a one that is more visually appealing, we kept the brand guidelines when constructing a logo for Ofis by sticking with the same font, color and square shapes. A large part of the project was putting out a single website that encapsulates both their architectural portfolio and showcases the caffe bar (which is yet to be opened). All the photographs of the projected houses that were incorporated in the site were made by me. ",
      year: "2021.",
      model: "",
      client: <a href="https://www.dragunarhitekti.hr/"> Dragun Arhitekti</a>,
      collaborator: "",
      thumbnail: "/img/thumbnail_dragun.jpg",
      images: [
        {
          src: "/img/da_logo.jpg",
          text: "",
        },
        {
          src: "/img/da_memorandum.jpg",
          text: "",
        },
        {
          src: "/img/da_torbe.jpg",
          text: "",
        },
        {
          src: "/img/da_majica.jpg",
          text: "",
        },
        {
          src: "/img/da_reklama.jpg",
          text: "",
        },
        {
          src: "/img/da_ofislogo.jpg",
          text: "",
        },
        {
          src: "/img/da_ofis1.jpg",
          text: "",
        },
        {
          src: "/img/da_ofis2.jpg",
          text: "",
        },
        {
          src: "/img/da_ofis3.jpg",
          text: "",
        },
         {
          src: "/img/da_signalizacija.jpg",
          text: "",
        },
        {
          src: "/img/da_radnovrijeme.jpg",
          text: "",
        },{
          src: "/img/thumbnail_dragun.jpg",
          text: "",
        }
      ],
    };

    const FF = {
      title: "Family Finances",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].VISUAL_IDENTITY,
      slug: "ff",
      text: "Family Finances is a mobile app for finance management. It's purpose is to easily track your finances, set a budget and view your expenses, aswell as share your budget and finances with others, using dedicated groups. The idea for the app logo was to take the visuals of statistics bars and form them into the letter 'F'.",
      year: "2020.",
      model: "",
      client: "Alpha Solutions j.d.o.o.",
      collaborator: "",
      thumbnail: "/img/thumbnail_ff.jpg",
      images: [
        {
          src: "/img/ff0.jpg",
          text: "",
        },
        {
          src: "/img/ff1.jpg",
          text: "",
        },
         {
          src: "/img/ff2.jpg",
          text: "",
        },
        {
          src: "/img/ff3.jpg",
          text: "",
        },
        {
          src: "/img/ff4.jpg",
          text: "",
        },
        {
          src: "/img/ff5.png",
          text: "",
        }

      ],
    };

    const VORTEX = {
      title: "Tattoo studio Vortex",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].VISUAL_IDENTITY,
      slug: "vortex",
      text: "Vortex is a small tattoo studio situated in Zagreb. The definition of the word Vortex is a mass of air or water that spins around very fast and pulls objects into its empty centre. Hence, the word is written in a swirling manner spinning around a tattoo needle that represents the vortex axis.",
      year: "2018.",
      model: "",
      client: "Vortex",
      collaborator: "",
      thumbnail: "/img/thumbnail_vortex.jpg",
      images: [
        {
          src: "/img/thumbnail_vortex.jpg",
          text: "",
        },
        {
          src: "/img/vortex1.jpg",
          text: "",
        },
        {
          src: "/img/vortex2.jpg",
          text: "",
        },
        {
          src: "/img/vortex3.jpg",
          text: "",
        },
        {
          src: "/img/vortex4.jpg",
          text: "",
        },
  

      ],
    };


    const INCISION = {
      title: "Incision",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].VISUAL_IDENTITY,
      slug: "incision",
      text: "Incision is a brand that produces handmade jewelry. A doe, the brand's mascot has been implemented with a surgical incision and a background in a shape of a (prismatic) crystal structure, given that the Incision jewelry is mainly made of crystals and minerals.",
      year: "2018.",
      model: "",
      client: "Marin Renić",
      collaborator: "",
      thumbnail: "/img/thumbnail_incision.jpg",
      images: [
        {
          src: "/img/incision1.jpg",
          text: "",
        },
        {
          src: "/img/incision2.jpg",
          text: "",
        },
        {
          src: "/img/incision3.jpg",
          text: "",
        },
        {
          src: "/img/incision4.jpg",
          text: "",
        },
        {
          src: "/img/incision5.jpg",
          text: "",
        },

      ],
    };

    const MILETIC = {
      title: "Miletić wines",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].PACKAGING,
      slug: "miletic",
      text: "Miletić wine is a homemade brand of traditional cuvée red wine that our family has been making for 40 years now. Eventhough the wine has a history, for the last few years the wine making process has been modernized and the technology has been updated, all of which called for a new branding. It's a regular practice to focus the name and the design of a homemade wine around traditional motifs, grandparents and their legacy, but since we twisted the wine recipe, we decided to make the youngest and most beloved member of our family the focal point of the design - our dog Boško. Boško, both protector of the winery and a welcoming host to our guests, has come to be the mascot of our family led tourism business in Pisak and hence the perfect face for our main product. ",
      year: "2021.",
      model: "Boško",
      client: "Self",
      collaborator: "",
      thumbnail: "/img/thumbnail_miletic.jpg",
      images: [
        {
          src: "/img/bosko2.jpg",
          text: "",
        },
        {
          src: "/img/bosko3.jpg",
          text: "",
        },
        {
          src: "/img/bosko4.jpg",
          text: "",
        },
        {
          src: "/img/bosko5.jpg",
          text: "",
        },

      ],
    };
 

    const VICKOV = {
      title: "Vickov wines",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].PACKAGING,
      slug: "vickov",
      text: "Vickov wines are 3 sorts of 'garage' wines that have been illustrated corresponding to their names: 'Turanj' - a traditional wine making press for fruits, 'Užanca' - a bussiness cutom or a habit, and 'Ćale moj' - a portrait of the client's father. ",
      year: "2021.",
      model: "",
      client: "Vickov vino",
      collaborator: "Rina Dubravec",
      thumbnail: "/img/thumbnail_vickov.jpg",
      images: [
        {
          src: "/img/lori1.jpg",
          text: "",
        }
       
      ],
    };

    const PONDI = {
      title: "Pondi",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].VISUAL_IDENTITY,
      slug: "pondi",
      text: "Pondi is a web hosting company that provides a place for sites on the internet. The letters of PONDI have been componed into a dynamic, connecting manner that represent Pondi fast service (line) connecting its clients (dots). Pondi line also secludes each dot (client) providing them with security and data protection - both assets that Pondi prides itself in.",
      year: "2018.",
      model: "",
      client: "Mid export-import d.o.o.",
      collaborator: "Ivona Mihajlović",
      thumbnail: "/img/thumbnail_pondi.jpg",
      images: [
        {
          src: "/img/pondi1.jpg",
          text: "",
        },
        {
          src: "/img/pondi2.jpg",
          text: "",
        },
        {
          src: "/img/pondi3.jpg",
          text: "",
        },
        {
          src: "/img/pondi4.jpg",
          text: "",
        },
        {
          src: "/img/pondi5.jpg",
          text: "",
        },
        {
          src: "/img/pondi6.jpg",
          text: "",
        },
        {
          src: "/img/pondi7.jpg",
          text: "",
        },
        {
          src: "/img/pondi8.jpg",
          text: "",
        }

      ],
    };


    const AHIMSA = {
      title: "Ahimsa ",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].VISUAL_IDENTITY,
      slug: "ahimsa",
      text: "Ahimsa (from sanscrit. compassion and care for all beings) is a charity association of Zadar University. The logo is derived from a hand, which symbolises the act of helping, and serves as the original Dhārmic Ahimsa symbol. By having two hands merge and represent a single individual, the meaning is transgressed to that of unity and integrity, echoing the association’s philosophy by which every human being in the world is connected and of the same value.",
      year: "2016.",
      model: "",
      client: "Udruga mladih Ahimsa",
      collaborator: "",
      thumbnail: "/img/thumbnail_ahimsa.jpg",
      images: [
        {
          src: "/img/thumbnail_ahimsa.jpg",
          text: "",
        },
        {
          src: "/img/ahimsa1.jpg",
          text: "",
        },
        {
          src: "/img/ahimsa2.jpg",
          text: "",
        },
        {
          src: "/img/ahimsa3.jpg",
          text: "",
        },
        {
          src: "/img/ahimsa4.jpg",
          text: "",
        },
        {
          src: "/img/ahimsa5.jpg",
          text: "",
        }


      ],
    };

    const INSULA = {
      title: "Insula",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].VISUAL_IDENTITY,
      slug: "insula",
      text: "The psychiatric hospital of Rab, after renaming itself to 'Insula - Rehabitional and Psychiatric County Hospital' in 2021., has issued a competition for creating its new visual identity. Eventhough my creative solution didn't win, I decided to put it in the portfolio because I still find it very functional. Since the new name of 'Insula' refers both to the insular cortex in the brain, and the Latin name for 'island', I tried combining a few motifs. By creating two floating hemispheres of the brain, the idea was to create both the letter 'I' with negative space around the brain. At the same time, by adding a dot to the 'I', a figure of a winged angel is starting to resemble. An angel represents security, protection, care, aid, help, assistance - all of which the psychiatric hospital of Rab provides to its patients. ",
      year: "2021.",
      model: "",
      client: "Psihijatrijska bolnica Rab",
      collaborator: "",
      thumbnail: "/img/thumbnail_insula.jpg",
      images: [
        {
          src: "/img/thumbnail_insula.jpg",
          text: "",
        },
        {
          src: "/img/insula2.jpg",
          text: "",
        },
        {
          src: "/img/insula3.jpg",
          text: "",
        },
        {
          src: "/img/insula4.jpg",
          text: "",
        },
        {
          src: "/img/insula5.jpg",
          text: "",
        },
        {
          src: "/img/insula6.jpg",
          text: "",
        },

      ],
    };

    const METALAC = {
      title: "Metalac cookware",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].ILLUSTRATION,
      slug: "metalac",
      text: "Osim tradicĳe na nacionalnoj razini, Metalac posuđe povezuje se i s tradicĳom na osobnoj. “Stena, čelik, patriota, sinonim za čvrste ruke, sigurnost i poverenje” neke su od vrĳednosti kojima se diči, karakterom i proizvodom. Ove odlike vežu se uz osjećaje doma. Također, uzevši u obzir njegovu dugovječnost i svrhu samog posuđa, simbolika Metalčevih proizvoda blisko se povezuje s ručkovima kodmajke ili bake - nedvojbeno jednim od najmelankoličnĳih motiva udobnosti doma, zajednice ili porodice. Ćilimi, nalazeći se u domovima mnogih srpskih baki, imajući tradicĳu dugu skoro 500 godina, te estetiku koja odražava identitet i nacionalnost - činili su se kao idealan vizual za Metalac posuđe. Povrh toga, dobivši zaštitu Geografske oznake, nedavno su uvaženi u službene nacionalne simbole Srbĳe, te su joj jedna od najvažnĳih rukotvorina. To je jedini proizvod sa takvom zaštitom u Srbĳi, a da potiče iz naroda i da pritom ima umjetničku vrĳednost. Nastao pod utjecajem Bugarske i Orĳentalne tapiserĳe, Pirotski ćilim, pri tkanju ima nekoliko pravila ne bi li bio autentičan i legitiman, te sam se ‘tkajući’ dizajn pokušala držati. Osim što srpski ćilim ima simetrično naličje, te je nerĳetko uokviren minimalno jednim vĳencem, pirotski ćilim ukrašen je simbolima, od kojih većina nema samo dekorativnu svrhu, već i semiotičko značenje, što znači da su ujedno slikovno pismo. Kombinacĳom navedenih pravila i simbola, dizajnirana su 4 različitih ‘ćilima’ ili pak detalja s ćilima, koji su s digitalizacĳom i mojom slobodnom interpretacĳom dobili dašak modernosti.Sva 4 dizajna mogu funkcionirati zasebno, ali su i prilagođeni čitavom setu posuđa.",
      year: "2020.",
      model: "",
      client: "Metalac Posuđe d.o.o.",
      collaborator: "",
      thumbnail: "/img/thumbnail_metalac.jpg",
      images: [
        {
          src: "/img/metalac1.jpg",
          text: "",
        },
        {
          src: "/img/metalac2.jpg",
          text: "",
        },
        {
          src: "/img/metalac3.jpg",
          text: "",
        },
        {
          src: "/img/metalac4.jpg",
          text: "",
        },
        {
          src: "/img/metalac5.jpg",
          text: "",
        },
        {
          src: "/img/metalac6.jpg",
          text: "",
        },
        {
          src: "/img/metalac7.jpg",
          text: "",
        },
        {
          src: "/img/metalac8.jpg",
          text: "",  
        },
        {
          src: "/img/metalac9.jpg",
          text: "",
        },
        {
          src: "/img/metalac10.jpg",
          text: "",
        }

      ],
    };

    const LORCA = {
      title: "Lorca poems",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].ILLUSTRATION,
      slug: "lorca",
      text: "While studying in Spain, the school has issued an assignment for making postcards based on Andalucian poems, for which I have chosen the works of the notorious Federico García Lorca. Lorca's poetry uses symbolism to convey feelings about death, love, vitality, fertility, and tragedy. Oftentimes, death was the prominent topic of his poetry. The most common symbols in the bulk of his work include the moon, water, blood, horses, grasses, metals and oranges. Even though these are all metaphors, he used these motives exactly as they were - concrete visuals, to create a picture that would seem surreal. For the 'Memento' poem I used the strong and clear motives of orange sun and the mint growing out of the frame for the scene where the poet is buried in the sand. Furthermore, I chose a color palette of bright red’s and oranges that would create a sandy atmosphere - with red being a color Lorca used regularly in his poems. The same ‘concrete’ approach was took with the 'Baile' poem. The clear motives to work with were a woman (Carmen) dancing, her white hair and yellow snake around her. To make it more surreal, and to accentuate Carmen having her ‘head in the clouds’, surrendering to her imagination of youth, I tried to integrate clouds that would stylistically be similar to the shape of her white hair, that represents her real age.",
      year: "2020.",
      model: "",
      client: "Escuela de Arte Sevilla",
      collaborator: "",
      thumbnail: "/img/thumbnail_lorca.jpg",
      images: [
        {
          src: "/img/lorca1.jpg",
          text: "",
        },
        {
          src: "/img/lorca2.jpg",
          text: "",
        },
        {
          src: "/img/lorca3.jpg",
          text: "",
        },
        {
          src: "/img/lorca4.jpg",
          text: "",
        },
        
      ],
    };

    const SHEEP = {
      title: "A Wild Sheep Chase",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].MISCELLANEOUS,
      slug: "sheep",
      text: "A photo taken for the purposes of making a book cover for Haruki Murakami's novel 'A Wild Sheep Chase'. The novel is a hybrid of mythology and mystery, important for it's political and allegorical views. Murakami blends elements of American and English literature with Japanese contexts, exploring post-WWII Japanese cultural identity, where the Sheep represents the hive-mind communist system of the era.",
      year: "2016.",
      model: "",
      client: "Arts Academy in Split",
      collaborator: "",
      thumbnail: "/img/thumbnail_sheep.jpg",
      images: [
        {
          src: "/img/sheep1.png",
          text: "",
        },
        {
          src: "/img/sheep2.jpg",
          text: "",
        },
        {
          src: "/img/sheep4.png",
          text: "",
        },
        {
          src: "/img/sheep3.jpg",
          text: "",
        }

      ],
    };

    const ISKON = {
      title: "Iskon",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].ILLUSTRATION,
      slug: "iskon",
      text: " Iskon is a Croatian ICT company that in 2015 organized a competition named ‘Iskon through the eyes of illustrators’. This was chosen among the work of 356 contestants as one of the 5 pieces to be printed onto Iskon merchandise. Iskon has been visualized as a fisherman and a sea wolf (croatian terminology for travel-ridden seamen) and he has a seemingly ordinary fishing net, but his net is anything but ordinary. It’s endless and it does not catch fish, on the contrary, it unites them. It is told when you dip your finger in the ocean that you are touching the entire world. A similar sense of connectivity occurs while splattering in the waters of the internet where, like the sea, the internet acts as a medium that conjoins and affiliates all the corners of the earth, just as Iskon himself connects and unifies all the fish in his net. ",
      year: "2015.",
      model: "",
      client: "Iskon Internet d.d. ",
      collaborator: "",
      thumbnail: "/img/thumbnail_iskon.jpg",
      images: [
        {
          src: "/img/iskon1.jpg",
          text: "",
        },
        {
          src: "/img/iskon2.jpg",
          text: "",
        },
        {
          src: "/img/iskon3.jpg",
          text: "",
        },
        {
          src: "/img/iskon4.jpg",
          text: "",
        }
      ],
    };

    const LUMOS = {
      title: "Lumos",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].VISUAL_IDENTITY,
      slug: "lumos",
      text: "Lumos is a psychotherapy center situated in Zagreb, focusing on playtherapy for children. For this reason, the logo has been modulated in 2 different ways with 2 different color palettes for better correspondance to 2 types of users - pink being for adults and the colorful one being for children. The brain is the main organ of the nervous system, influencing the psyhological life of every living person, making it inevitably the ultimate symbol for psychotherapy. The typography is derived from the word Lumos, which is written in a way to resemble the shape of the cerebral cortex and its gyri. The color is derived from a combination of the real colors of the brain - pink and gray, which at the same time reliably represent the characteristics of the center Lumos - pink is a gentle and empathetic color, it represents attachment, harmony, inner peace, compassion, care and accessibility, while with a touch of gray it gives stability. The gray color also represents neutrality - the main characteristic of a psychotherapist who conducts a high quality analysis. ",
      year: "2023.",
      model: "",
      client: "Lumos Centar j.d.o.o.",
      collaborator: "",
      thumbnail: "/img/thumbnail_lumos.jpg",
      images: [
     {
          src: "/img/lumosznak.jpg",
          text: "",
        },
        {
          src: "/img/lumosobjasnjenje.jpg",
          text: "",
        },
        {
          src: "/img/lumoslogokratki.jpg",
          text: "",
        },
        {
          src: "/img/lumosboje.jpg",
          text: "",
        },
        {
          src: "/img/lumospattern.jpg",
          text: "",
        },
        {
          src: "/img/lumostipografija.jpg",
          text: "",
        },
        {
          src: "/img/lumoslogodugi.jpg",
          text: "",
        },
        {
          src: "/img/lumostabla.jpg",
          text: "",
        },
        {
          src: "/img/lumosbojedjecje.jpg",
          text: "",
        },
         {
          src: "/img/lumosigraona.jpg",
          text: "",
        },
        {
          src: "/img/lumosploca.jpg",
          text: "",
        }
      ],
    };

    const MATCHKO = {
      title: "Matchko app",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].MISCELLANEOUS,
      slug: "matchko",
      text: "Site is under construction.",
      year: "2023.",
      model: "",
      client: "Startup project",
      collaborator: "Tina Lucić",
      thumbnail: "/img/thumbnail_matchko.jpg",
      images: [
     
        
      ],
    };

   

    const BEDALOV = {
      title: "Bedalov wines",
      category: DESIGN,
      subcategory: ROUTE_MAPPING[DESIGN].PACKAGING,
      slug: "bedalov",
      text: "7 is a meaningful number for Bedalov wines. There is a total of 7 sorts of Bedalov wines that correspond to 7 castles in Kaštela (a city consisting of 7 smaller municipalities). Their original logo also consists of 7 grains of grapes, each representing a single castle and a single wine, together representing a unified cluster of grapes - wine's main ingredient. The idea for wine packaging was to continue the emphasis on number 7 and make the etiquette resemble the grape logo by dispersing the etiquette content into 7 stickers. The color of the etiquette is either black or white, corresponding to the wine color. ",
      year: "2022.",
      model: "",
      client: "OPG Jakša Bedalov",
      collaborator: "",
      thumbnail: "/img/thumbnail_bedalov.jpg",
      images: [
     {
          src: "/img/bedalovlogo.jpg",
          text: "",
        },
        {
          src: "/img/bedalov1.jpg",
          text: "",
        },
        {
          src: "/img/thumbnail_bedalov.jpg",
          text: "",
        },
        {
          src: "/img/bedalov2.jpg",
          text: "",
        },
        
      ],
    };

export const DATA = [EIE4, EIE3, EIE, SYNC, ANGELINA, SPLIT, ANA, SLAVENA, PROCESSING, MEDITERRA, KARANTINA, MIRAGE, CLIO, MAMMALIA, HEN, FRENS, SMOLL, DEA, KITCHEN, LUMOS, MATCHKO, BEDALOV, ONCE, ALPHA, NFT, SUNSEARAY, DRAGUN, MILETIC, INSULA, VICKOV, LORCA, METALAC, FF, INCISION, VORTEX, PONDI, SHEEP, AHIMSA, ISKON ];
